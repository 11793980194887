<template>
  <a-spin tip="Loading..." :spinning="loading">
    <div class="login"></div>
  </a-spin>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { iosLoginOrRegist } from '@/utils'

export default defineComponent({
  setup () {
    const loading = ref(true)

    return {
      loading
    }
  },

  created () {
    const code = this.$route.query.code

    if (code) {
      this.$store.commit('SET_PORT', 'scan')
      this.$store.dispatch('WxGZHLogin', code).then(() => {
        iosLoginOrRegist('/waimai/login')
        this.$message.success('扫码成功')

        this.$router.push({ name: 'waimai-index' })
      })
    } else {
      const storeId = this.$route.query.storeId
      this.$store.commit('SET_SCAN_STORE_ID', storeId)

      this.$store.dispatch('SnsapiBase3')
    }
  }
})
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
}
</style>
